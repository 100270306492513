module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Xiaohan Fu","short_name":"Xiaohan Fu","description":"Build at 2024-08-15T04:21:37+00:00","start_url":"/","background_color":"#304CFD","theme_color":"#304CFD","display":"standalone","cache_busting_mode":"none","icon":"./static/logo.png","legacy":true,"theme_color_in_head":true,"crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1000,"quality":80,"showCaptions":true,"linkImagesToOriginal":true,"markdownCaptions":false,"backgroundColor":"white","withWebp":false,"withAvif":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["UA-XXXXXXXXX-X"],"gtagConfig":{}},
    },{
      plugin: require('../node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"black","showSpinner":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-lunr/gatsby-browser.js'),
      options: {"plugins":[],"languages":[{"name":"en","plugins":[null]}],"fields":[{"name":"title","store":true,"attributes":{"boost":20}},{"name":"abstract","store":true},{"name":"content","store":true},{"name":"url","store":true}],"resolvers":{"Mdx":{}},"filename":"search_index.json","fetchOptions":{"credentials":"same-origin"}},
    },{
      plugin: require('../node_modules/gatsby-theme-academic/gatsby-browser.js'),
      options: {"plugins":[],"contentPath":"content","googleAnalyticTrackingId":"UA-XXXXXXXXX-X","defaultLanguage":"en","pages":{"home":"/","posts":"posts","contact":"contact","resume":"resume","tags":"tags","research":"research"},"tagColors":["magenta","red","volcano","orange","gold","lime","green","cyan","blue","geekblue","purple"],"author":"Xiaohan Fu","icon":"./static/logo.png"},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
